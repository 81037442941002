import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';


import { MatNativeDateModule } from '@angular/material/core';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './service/auth-interceptor';
import { DialogModule } from './service/dialog/dialog.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#204000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'classic',
  type: 'info',
  content: {
    "href": "https://ljp.darub.se",
  }
};

const icons = [
  // ... other icons
  faShare,
];

const customConfig: ShareButtonsConfig = {
  include: ['facebook', 'twitter', 'google'],
  exclude: [],
  theme: 'modern-light',
  gaTracking: true,
  autoSetMeta: true,
  twitterAccount: 'username',
  moreButtonAriaLabel: 'Visa dela-knappar',
  lessButtonAriaLabel: 'Dölj dela-knappar',
  moreButtonIcon: 'share',
  //lessButtonIcon: 'share',
    prop: {
      copy: {
        text: 'Kopiera länk',
        ariaLabel: 'Kopiera länk till artikel',
      },
      email: {
        text: 'E-Post',
        ariaLabel: 'Dela artikel via E-Post',
      },
      facebook: {
        text: 'Facebook',
        ariaLabel: 'Dela artikel via Facebook',
      },
      twitter: {
        text: 'X (fd Twitter)',
        ariaLabel: 'Dela artikel via X (Tvitter)',
      }
    }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    //FontAwesomeModule,
    //FaIconLibrary,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule, HttpClientModule, HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatToolbarModule, MatIconModule, MatTableModule, MatTabsModule, MatFormFieldModule,
    MatSelectModule, MatInputModule, MatButtonModule, MatMenuModule,
    MatSidenavModule, MatTreeModule, MatListModule, MatGridListModule,
    MatCardModule, MatDatepickerModule, MatNativeDateModule, MatSnackBarModule,
    MatProgressSpinnerModule, FormsModule, ReactiveFormsModule, MatDialogModule,
    DialogModule, MatTooltipModule,
    LayoutModule, MatSliderModule,
    ShareIconsModule,
    ShareButtonsModule.withConfig(customConfig),

  ],
  providers: [ { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true } ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...icons);
  }
}