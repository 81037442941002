import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';



const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },

  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'play', loadChildren: () => import('./play/play.module').then(m => m.PlayModule) },
  { path: 'subscribe', loadChildren: () => import('./subscribe/subscribe.module').then(m => m.SubscribeModule) },
  { path: 'fileshare', loadChildren: () => import('./fileshare/fileshare.module').then(m => m.FileshareModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'review', loadChildren: () => import('./review/review.module').then(m => m.ReviewModule) },

  { path: '', loadChildren: () => import('./play/play.module').then(m => m.PlayModule) },
  { path: '**', loadChildren: () => import('./play/play.module').then(m => m.PlayModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
