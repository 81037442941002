import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { DialogService } from '../service/dialog/dialog.service';
import { MenuStateService } from '../service/menu-state.service';

const itemData = [
  { active: true, title: 'Lyssna', subTitle: 'För dig som lyssnar på tidningar', icon: '', image: 'speaker', route: '/play/view', text: 'Lyssna på ett nummer av någon av våra Taltidningar.'},
  { active: false, title: 'Hantera prenumeration', subTitle: 'För lyssnare och redaktörer', icon: '', image: 'home', route: '/subscribe', text: 'Meddela om en ny prenumerant, adressändring eller uppsägning.'},
  { active: true, title: 'Producera', subTitle: 'För redaktörer', icon: '', image: 'create', route: '/admin', text: 'Skapa ett nytt nummer av din taltidning.'},
  { active: false, title: 'Ladda upp material', subTitle: 'För inläsare', icon: '', image: 'attach_file', route: '/fileshare', text: 'Ladda upp ljudfiler som du har läst in för en bok eller tidning.'},
  { active: false, title: 'Korrektur', subTitle: 'För dig som beställt inläsning.', icon: '', image: 'hearing', route: '/review', text: 'Korrekturlyssna på din beställning.'},
  { active: false, title: 'Hjälp', subTitle: 'För alla', icon: '', image: 'help_outline', route: '/about', text: 'Berätta med om vad det här är'},
];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  confirm$: Observable<boolean>;
  items = itemData.filter(x => x.active);
  constructor(private menuState: MenuStateService) {
    this.menuState.pushContextMenuChange([ ]);
    this.menuState.pushShowMenu(true);
    this.menuState.pushShowToolbar(true);
  }

  ngOnInit(): void {

  }

}
