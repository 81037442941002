<div class="card-wrapper">
    <div class=card-container *ngFor="let item of items">
        <mat-card class="ljp-home-card" routerLink="{{ item.route }}">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"><mat-icon style="margin: auto; font-size: 150px;">{{ item.icon }}</mat-icon></div>
          <mat-card-title>{{ item.title }}</mat-card-title>
          <mat-card-subtitle>{{ item.subTitle }}</mat-card-subtitle>
        </mat-card-header>
        <div style="margin: auto; text-align: center;">
            <mat-icon color="accent" class="large-icon-150">{{ item.image }}</mat-icon>
        </div>
        <mat-card-content>
          <p>
            {{ item.text }}
          </p>
        </mat-card-content>
        <mat-card-actions>
          <!--<button mat-button>LIKE</button>
          <button mat-button>SHARE</button>-->
        </mat-card-actions>
      </mat-card>
    </div>
</div>
