import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models';
import { tap } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';

import * as moment from 'moment';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })
export class AuthService {

    //    baseUrl = 'http://localhost:9000';
    baseUrl = '';
    user: User;
    expireSeconds = 3600;

    constructor(private http: HttpClient, private userService: UserService, private router: Router) {
    }

    async getUser(): Promise<User> {
        if (!this.user) {
            try {
                this.user = await this.userService.getMe().toPromise();
            } catch (err) {
                console.log(err);
            }
        }
        return this.user;
    }

    logout(): void {
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.router.navigate(['login']);
    }

    login(email: string, password: string): Observable<User> {
        return this.http.post<User>(`${this.baseUrl}/auth/local`, { email, password })
            .pipe(tap(res => this.setSession(res)))
            //    .do(res => this.setSession)
            .pipe(shareReplay());
    }

    private setSession(authResult): void {
        const expiresAt = moment().add(this.expireSeconds, 'second');

        localStorage.setItem('id_token', authResult.token);
        localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

        this.userService.getMe()
            .subscribe(
                user => {
                    this.user = user;
                }
            );
    }

    public isLoggedIn(): boolean {
        const loggedIn = moment().isBefore(this.getExpiration());
        if (loggedIn) {
            const expiresAt = moment().add(this.expireSeconds, 'second');
            localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
        }
        return loggedIn;
    }

    isLoggedOut(): boolean {
        return !this.isLoggedIn();
    }

    getExpiration(): any {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }
}

