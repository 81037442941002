<div class="main">
    <form [formGroup]="form">
        <mat-card>
            <mat-card-title> Login </mat-card-title>
            <mat-card-content>
                <div class="ljp-form">
                <mat-form-field appearance="outline" class="ljp-form-field-450">
                    <input matInput placeholder="E-Mail" name="email" formControlName="email">
                </mat-form-field>

                <mat-form-field appearance="outline" class="ljp-form-field-450">
                    <input type="password" matInput placeholder="Password" name="password" formControlName="password"
                        (keyup.enter)="login()">
                </mat-form-field>

                <p style="color: red;">{{errorMessage}}</p>
                </div>
            </mat-card-content>

            <mat-card-actions class="saveClosebuttons">
                <button mat-button color="primary" (click)="login()">
                    Login
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>