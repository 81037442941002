import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Article, Organization } from '../models';
import { Observable, of } from 'rxjs';
import { DialogService } from './dialog/dialog.service';
import { MenuStateService } from './menu-state.service';
import { tap, mergeMap, map } from 'rxjs/operators';
import { LjpHttpService } from './ljp-http.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends LjpHttpService {

  service = 'customers';

  getList(): Observable<[Organization]> {
    return super.getList(this.withBaseUrl(this.service, '/'), 'Organisationer') as Observable<[Organization]>;
  }

  getItem(id: string): Observable<Organization> {
    return super.getItem(this.withBaseUrl(this.service, '/', id), 'Organisation') as Observable<Organization>;
  }

  saveItem(item: Organization): Observable<Organization> {
    return super.save(this.withBaseUrl(this.service, '/', item._id), item, item.name);
  }

  async removeItem(id: string, name: string): Promise<boolean> {
    return await super.removeItem(this.withBaseUrl(this.service, '/', id), name);
  }
}
