import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuID, MenuItem, MenuStateService } from './service/menu-state.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './service/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, filter } from 'rxjs/operators';
import { User } from './models';
import { UserDialogComponent } from './admin/organizations/organization/user-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserService } from './service/user.service';
// import { ActivatedRoute, Router } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { PublicationService } from './service/publication.service';
import { AudioService } from './service/audio.service';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  events: string[] = [];
  opened = true;
  userMenu: MenuItem[] = [
    { title: 'Login', action: 'route', url: '/login', },
    { title: 'Logout', action: 'function', func: this.logout(this.authService), },
    { title: 'Inställningar', action: 'function', func: this.showUser(this.dialog, this.authService), },
  ];

  contextMenuSubscription: Subscription;
  navMenuSubscription: Subscription;
  toolbarInfoSubscription: Subscription;
  messageSubscription: Subscription;
  loadingSubscription: Subscription;
  showMenuSubscription: Subscription;
  showUserMenuSubscription: Subscription;
  showToolbarSubscription: Subscription;
  selectedThemeSubscription: Subscription;
  todoMenuSubscription: Subscription;

  isLoading = false;
  showMenu = false;
  showUserMenu = true;
  showToolbar = true;
  selectedTheme = 'default-theme';
  toolbarTitle = 'Ljudportalen';
  toolbarHeight = '';
  toolbarImageUrl = '';
  backgroundColor = '';
  audioState: any;

  contextMenu: MenuItem[] = [{ title: '', action: 'none' }];
  navigationMenu: MenuItem[] = [
    { title: 'Hem', url: '/', action: 'route', tooltip: 'Hem', icon: 'home' },
    {
      id: MenuID.pub, title: 'Publikationer', url: '/admin/publications', action: 'route', tooltip: 'Lista dina Publikationerer',
      icon: 'menu_book', subTitle: '', subMenu: [
        { title: 'Hem 1', url: '/', action: 'route', tooltip: 'Min favorit', icon: 'home' }]
    },
    {
      id: MenuID.orgs, title: 'Organisationer', url: '/admin/organizations',
      action: 'route', tooltip: 'Lista dina nummer', icon: 'group', subTitle: 'Lista dina nummer'
    },
  ];

  todoMenu: MenuItem[] = [];
  toolbarInfo: MenuItem[] = [{ title: '', action: 'none' }];

//keep refs to subscriptions to be able to unsubscribe later
private popupOpenSubscription!: Subscription;
private popupCloseSubscription!: Subscription;
private initializingSubscription!: Subscription;
private initializedSubscription!: Subscription;
private initializationErrorSubscription!: Subscription;
private statusChangeSubscription!: Subscription;
private revokeChoiceSubscription!: Subscription;
private noCookieLawSubscription!: Subscription;

  constructor(private snackBar: MatSnackBar, public menuState: MenuStateService,
    public authService: AuthService, private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog, private userService: UserService, private router: Router,
    private pubService: PublicationService, private audioService: AudioService, private cookieService: NgcCookieConsentService, private translateService: TranslateService) {

    /*
    router.events.subscribe(event => {
      this.navigationInterceptor(event);
    });*/

    //this.grabTheTrackId(); //<------------------ call this function first , which will load the gtag.js on base of different track Id in async manner

    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log(event);
      /*gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });*/
    });
    /** END : Code to Track Page View  using gtag.js */


    this.contextMenuSubscription = this.menuState.contextMenuChange$.subscribe((menu: MenuItem[]) => {
      this.contextMenu = menu;
    });

    this.navMenuSubscription = this.menuState.navMenuChange$.subscribe((menu: MenuItem[]) => {
      this.navigationMenu = menu;
    });

    this.todoMenuSubscription = this.menuState.todoMenuChange$.subscribe((menu: MenuItem[]) => {
      this.todoMenu = menu;
    });

    this.audioService.getState()
      .subscribe(state => {
        this.audioState = state;
      });

    this.toolbarInfoSubscription = this.menuState.toolbarInfo$.subscribe((menu: MenuItem[]) => {
      const title = menu.filter(x => x.action === 'title')[0]?.title;
      if (title || title === '') {
        this.toolbarTitle = title;
      } else {
        this.toolbarTitle = 'Ljudportalen';
      }

      const height = menu.filter(x => x.action === 'height')[0]?.data;
      if (height) {
        this.toolbarHeight = height;
      }

      const image = menu.filter(x => x.action === 'imageUrl')[0]?.data;
      if (image) {
        this.toolbarImageUrl = image;
      }

      const backgroundColor = menu.filter(x => x.action === 'backgroundColor')[0]?.data;
      if (backgroundColor) {
        this.backgroundColor = backgroundColor;
      }

      this.toolbarInfo = menu.filter(x => x.isControlItem !== true);
    });

    this.messageSubscription = this.menuState.message$.subscribe(item => {
      this.openSnackBar(item.message, item.action, item.duration);
    });

    this.loadingSubscription = this.menuState.loading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });

    this.showMenuSubscription = this.menuState.showMenu$.subscribe(show => {
      this.showMenu = show;
      // this.loadUser();
    });

    this.showUserMenuSubscription = this.menuState.showUserMenu$.subscribe(show => {
      this.showUserMenu = show;
      if (this.showUserMenu) {
        this.loadUser();
      }
    });

    this.showToolbarSubscription = this.menuState.showToolbar$.subscribe(show => {
      this.showToolbar = show;
    });

    this.selectedThemeSubscription = this.menuState.selectedTheme$.subscribe(theme => {
      this.selectedTheme = theme;
    });
  }

  async loadUser(): Promise<void> {
    const user = await this.authService.getUser();
    if (user.defaultPubId) {
      const pub = await this.pubService.getItem(user.defaultPubId).toPromise();
      for (const item of this.navigationMenu) {
        if (item.id === MenuID.pub) {
          item.subMenu[0].title = pub.name;
          item.subMenu[0].url = `/admin/publication/${user.defaultPubId}`;
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.authService.isLoggedIn()) {
      this.loadUser();
    }
    // console.log('this.authService.isLoggedIn', this.authService.isLoggedIn());
    /*if (this.showMenu) {
      const user = await this.authService.getUser();
      if (user.defaultPubId) {
        const pub = await this.pubService.getItem(user.defaultPubId).toPromise();
        for (const item of this.navigationMenu) {
          if (item.id === MenuID.pub) {
            item.subMenu[0].title = pub.name;
            item.subMenu[0].url = `/admin/publication/${user.defaultPubId}`;
          }
        }
      }
    }*/
    this.translateService.addLangs(['sv', 'en']);
    this.translateService.setDefaultLang('sv');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/sv|en/) ? browserLang : 'sv');
    // console.log(`${browserLang} ${browserLang.match(/sv|en/) ? browserLang : 'sv'}`);
    this.translateService//
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {
        // console.log(data);
        this.cookieService.getConfig().content = this.cookieService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.cookieService.getConfig().content.header = data['cookie.header'];
        this.cookieService.getConfig().content.message = data['cookie.message'];
        this.cookieService.getConfig().content.dismiss = data['cookie.dismiss'];
        this.cookieService.getConfig().content.allow = data['cookie.allow'];
        this.cookieService.getConfig().content.deny = data['cookie.deny'];
        this.cookieService.getConfig().content.link = data['cookie.link'];
        this.cookieService.getConfig().content.policy = data['cookie.policy'];

        this.cookieService.destroy(); // remove previous cookie bar (with default messages)
        this.cookieService.init(this.cookieService.getConfig()); // update config with translated messages
      });


    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.initializingSubscription = this.cookieService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        // console.log(`initializing: ${JSON.stringify(event)}`);
      });
    
    this.initializedSubscription = this.cookieService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        // console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.cookieService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize... 
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      });

      this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      });
  }

  ngOnDestroy(): void {
    this.contextMenuSubscription.unsubscribe();
    this.navMenuSubscription.unsubscribe();
    this.toolbarInfoSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.showMenuSubscription.unsubscribe();
    this.showToolbarSubscription.unsubscribe();
    this.selectedThemeSubscription.unsubscribe();
  }

  logout(authService: AuthService): any {
    return function inner(): void {
      authService.logout();
    };
  }

  showUser(dialog: MatDialog, authService: AuthService): any {
    return async function showUserInner(): Promise<void> {



      const user: User = await authService.getUser();
      const dialogRef = dialog.open(UserDialogComponent, {
        width: '700px',
        data: { user }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    };
  }

  openSnackBar(message: string, action: string, duration: number = 2000): void {
    this.snackBar.open(message, action, { duration });
  }

  showInfo(title: string, info: string): void {
    this.openSnackBar(info, title);
  }

  sendAction(item: MenuItem): void {
    if (item.action === 'route') {
      if (item.url.startsWith('http')) {
        window.location.href = item.url;
      } else {
        this.router.navigateByUrl(item.url);
      }
    } else if (item.action === 'external') {
      window.location.href = item.url;
    } else {
      this.menuState.pushMenuAction(item);
    }
  }

  onMenuClick(selection: string): void {

  }

  grabTheTrackId() {
    //Read the value of track id obtained in the global config variable.
    let gaTrackId = 'G-35S2DZKF7R'; // this.config.getConfig('trackId');

    //Add custom element of script with following attributes
    let customGtagScriptEle: HTMLScriptElement = document.createElement('script');
    customGtagScriptEle.async = true;
    customGtagScriptEle.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackId;
    document.head.prepend(customGtagScriptEle);
    gtag('config', 'G-35S2DZKF7R' /*environment.GA_TRACKING_ID*/, { send_page_view: false });
  }
}
