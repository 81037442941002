<h1 mat-dialog-title>Användare</h1>
<div mat-dialog-content>
  <div class="ljp-form">

    <mat-form-field appearance="outline" class="ljp-form-field-450">
      <mat-label>Namn</mat-label>
      <input matInput [(ngModel)]="data.user.name">
    </mat-form-field>

  <mat-form-field appearance="outline" class="ljp-form-field-450" style="width: 100%">
    <mat-label>Info</mat-label>
    <textarea matInput [(ngModel)]="data.user.info" rows="5"></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="ljp-form-field-450">
    <mat-label>E-Post</mat-label>
    <input matInput [(ngModel)]="data.user.email">
  </mat-form-field>

  <mat-form-field appearance="outline" class="ljp-form-field-450">
    <mat-label>Roll</mat-label>
    <mat-select [(ngModel)]="data.user.role">
      <mat-option *ngFor="let role of [ { _id: 'user', name: 'Användare' }, { _id: 'admin', name: 'Admin' } ]" value="{{role._id}}">{{role.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="ljp-form-field-450" *ngIf="!data.orgId">
    <mat-label>Organisation</mat-label>
    <mat-select [(ngModel)]="data.user.customer">
      <mat-option *ngFor="let org of data.orgs" value="{{org._id}}">{{org.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <ul >
    <li *ngFor="let pub of data.user.extraPubIds" class="soundfile" style="display: flex; align-items: center; margin-bottom: 10px;">
        {{getPubName(pub)}}
        <button mat-icon-button aria-label="redigera" (click)="unlink(file)">
          <mat-icon color="accent">link_off</mat-icon>
        </button>
        <span>{{pub.name}}</span>
      </li>
</ul>

  <div>
    <mat-form-field appearance="outline" class="ljp-form-field-450">
      <mat-label>Extra publikationer</mat-label>
      <mat-select [(ngModel)]="pubToAdd">
        <mat-option *ngFor="let file of data.pubs" [value]="file">{{file.name}}</mat-option>
      </mat-select>
      
    </mat-form-field>
    <button *ngIf="pubToAdd" style="margin-left: 20px;" mat-mini-fab aria-label="lägg till" (click)="link(pubToAdd)">
      <mat-icon color="primary">add</mat-icon>
    </button>
  </div>

  <mat-form-field appearance="outline" class="ljp-form-field-450">
    <mat-label>Standard publikation</mat-label>
    <mat-select [(ngModel)]="data.user.defaultPubId">
      <mat-option *ngFor="let pub of data.pubs" value="{{pub._id}}">{{pub.name}}</mat-option>
    </mat-select>
  </mat-form-field>


</div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Avbryt</button>
  <button mat-button (click)="save()" cdkFocusInitial>Spara</button>
</div>