import { Injectable } from '@angular/core';
import { User } from '../models';
import { Observable } from 'rxjs';
import { LjpHttpService } from './ljp-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends LjpHttpService {

  service = 'users';

  getList(): Observable<[User]> {
    return super.getList(this.withBaseUrl(this.service, '/'), 'Användare') as Observable<[User]>;
  }

  getListByCustomer(id: string): Observable<[User]> {
    return super.getList(this.withBaseUrl(this.service, '/byCustomer', id), 'Användare') as Observable<[User]>;
  }

  getItem(id: string): Observable<User> {
    return super.getItem(this.withBaseUrl(this.service, '/', id), 'Användare') as Observable<User>;
  }

  getMe(): Observable<User> {
    return super.getItem(this.withBaseUrl(this.service, '/me'), 'Användare') as Observable<User>;
  }

  saveItem(item: User): Observable<User> {
    return super.save(this.withBaseUrl(this.service, '/', item._id), item, item.name);
  }

  async removeItem(id: string, name: string): Promise<boolean> {
    return await super.removeItem(this.withBaseUrl(this.service, '/', id), name);
  }
}
